<template>
  <div>
    <div class="full-width category-container m-t-36">
      <div class="tac container neue neueSettings">
        <Breadcrumbs :path="path" />
      </div>
    </div>

    <section class="full-width" id="hero" v-if="article._embedded['wp:featuredmedia']" :style="'background-image: url(' + article._embedded['wp:featuredmedia']['0'].source_url + ');'" >
      <div class="container" style="display:flex; flex-direction:column;justify-content:space-between;position:relative;z-index:1;">
        <div class="heroInfos">
          <div class="col-50">
          </div>
          <div class="col-50 rightAlign">
            <p>
              Condividi l'articolo
              &nbsp;
              <a :href="'https://www.facebook.com/sharer/sharer.php?u=' + location" target="_BLANK" class="socialRecipe"><span class="fa fa-facebook-f" aria-hidden="true"></span></a>
              &nbsp;
              <a :href="'whatsapp://send?text=' + location" target="_BLANK" class="socialRecipe"><span class="fa fa-whatsapp" aria-hidden="true"></span></a>
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="full-width">
      <div class="container fusion-body">
        <div v-html="article.content.rendered" />
      </div>
    </section>

    <section class="full-width">
      <div class="container" style="margin:2rem 0;">
        <h3 class="recipeIngredients"><b>Per questa ricetta abbiamo usato: </b></h3>
      </div>
    </section>
    <div class="full-width gray-bg">
      <div class="container" style="display: block;padding: 30px 0">
        <ProductSlider />
      </div>
    </div>
  </div>
</template>

<script>
    import Breadcrumbs from '@/components/Breadcrumbs'
    import ProductSlider from '@/components/ProductSlider'
    import axios from 'axios'

    export default {
      name : 'Recipe',
      components : {
        Breadcrumbs,
        ProductSlider
      },
      data(){
        return{
          url: require('@/assets/ricette1.jpg'),
          urlVideo: require('@/assets/ricette1.jpg'),
          article : {},
          articleContent : '',
          path: [
            { label: 'Home', path: '/' },
            { label: 'Blog', path: '/blog' },
            //{ label: 'Semifreddo alle Mandorle', path: '/recipe/' + this.$route.params.slug },
          ],
          settings: {
        "dots": true,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 4,
        "slidesToScroll": 4,
        "swipeToSlide": false,
        "touchThreshold": 2,
        "responsive": [
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 3,
            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2,
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1
            }
          }
        ]
      }
        }
      },
      async created(){
        await axios.get(`https://altromercato.it/wp-json/wp/v2/posts/${this.$route.params.id}/?_embed`)
          .then((res) => {
            this.article = res.data
            this.articleContent = this.article.content.rendered

            this.path.push({
              label: this.article.title.rendered,
              path: '/'
            })
          })
            .then(() => console.log(this.article))
          const video = document.getElementById('video');
          video.children[0].removeAttribute('style')
        }  
    }
</script>
<style>
  .container{
    width: 100%!important;
  }

  .fusion-text p {
    font-family: 'caecilia', sans-serif;
  }
</style>
<style scoped>
  @media (max-width: 768px){
    .container{
      padding: 0 20px;
    }
    #hero{
      height: 500px!important;
    }
    #hero .container{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      z-index: 1;
      height: 100%;
    }
    #hero .heroInfos{
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      height: 180px;
    }
    .col-50{
      max-width: 100%!important;
    }
    #video .container{
      align-items: center;
    }
    #video .container span{
      width: fit-content;
    }
    #aboutAuthor{
      height: 300px;
    }
    #aboutAuthor .container, #aboutAuthor .row{
      height: 100%;
      flex-direction: column;
      align-items: flex-start!important;
      justify-content: space-between!important;
    }
    #abourAuthor .row img{
      margin-left: 0!important;
    }
    #aboutAuthor .row p{
      margin-left: 0!important;
    }
  }
  @media (max-width: 1024px){
    .container{
      padding: 0 20px;
    }
    #hero{
      height: 500px!important;
    }
    #hero .container{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      z-index: 1;
      height: 100%;
    }
    #hero .heroInfos{
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      height: 180px;
    }
    .col-50{
      max-width: 100%!important;
    }
    #video .container{
      align-items: center;
    }
    #video .container span{
      width: fit-content;
    }
    #aboutAuthor{
      height: 300px;
    }
    #aboutAuthor .container, #aboutAuthor .row{
      height: 100%;
      flex-direction: column;
      align-items: flex-start!important;
      justify-content: space-between!important;
    }
    #abourAuthor .row img{
      margin-left: 0!important;
    }
    #aboutAuthor .row p{
      margin-left: 0!important;
    }
  }
  .container{
    width: 100%!important;
  }
  ul{
    margin: 10px -20px;
  }
  ul li{
    font-weight: lighter;
    font-size: 15px;
  }
  h1,h2,h3,h4,h5,h6,p{
    margin: 0;
  }
  h1,h2,h3,h4,h5,h6{
    font-family: neue-haas-grotesk-display, sans-serif;
    letter-spacing: 0.4px;
  }
  a.socialRecipe {
    color: white;
    max-width: 32px;
    max-height: 32px;
    padding: 6px 10px;
    border-radius: 50%;
    background: black;
    cursor: pointer;
  }
  .heroInfos{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .col-50{
    max-width: 50%;
  }
  .col-48{
    max-width: 48%;
    width: 48%;
  }
  .rightAlign{
    text-align: right;
  }
  #hero{
    height: 700px;
    padding: 60px 0;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }
  #hero::after{
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color:white;
    opacity: 0.75;
  }
  #hero .articleTitle{
    position: relative;
    z-index: 1;
    font-size: 25px;
  }
  #recipeDescr{
    padding: 60px 0;
  }
  #recipeDescr h3{
    margin-bottom: 1rem;
    font-weight: 900;
  }
  #video{
    height: 700px;
    padding: 60px 0;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #video::after{
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color:black;
    opacity: 0.75;
  }
  #video .container {
    justify-content: center;
  }
  #video .container span{
    position: relative;
    z-index: 1;
    color: white;
    font-size: 98px;
  }
  #recipeFull{
    padding: 60px 0;
  }
  #recipeFull h3{
    margin-bottom: 1rem;
    font-weight: 900;
  }
  #aboutAuthor{
    padding: 0rem 0 4rem 0;
  }
  .recipeIngredients{

  }
</style>
